"use client"

import { toast } from "sonner"
import { z } from "zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Form from "@radix-ui/react-form"
import { Button, FormFieldInput, useAuthModal } from "@store-platform/ui/common"
import React from "react"
import {
  assignClerkAPIErrorsToForm,
  cn,
  formErrorClassName,
} from "@store-platform/utils"
import { useSignIn } from "@clerk/nextjs"
import { useRouter } from "next/navigation"
import { useAnalytics } from "@store-platform/backend/api-client"

const formSchema = z.object({
  email: z
    .string({
      required_error: "Email is required",
    })
    .email("Invalid email")
    .min(3, "Invalid email length"),
  password: z.string({
    required_error: "Password is required",
  }),
})

type FormSchemaType = z.infer<typeof formSchema>

type LoginFormProps = {
  site?: "launch.app" | "store.app"
  redirectTo?: string
  classNames?: {
    submitButton?: string
  }
}

export const SignInForm = (props: LoginFormProps) => {
  const { isLoaded, signIn, setActive } = useSignIn()
  const router = useRouter()
  const { trackEvent } = useAnalytics()
  const { onOpenChange, redirectTo: modalRedirect } = useAuthModal()
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  })

  const processForm: SubmitHandler<FormSchemaType> = async (data) => {
    if (!isLoaded) {
      toast.error("Sign in is not available at the moment")
      return
    }

    try {
      await signIn.create({
        strategy: "password",
        identifier: data.email,
        password: data.password,
      })

      if (signIn.createdSessionId) {
        onOpenChange(false)
        await setActive({ session: signIn.createdSessionId })
        trackEvent({
          obj: "User",
          action: "Signed In",
          properties: {
            site: props.site ?? "store.app",
            email: data.email,
          },
        })
        if (modalRedirect || props.redirectTo) {
          // workaround to conflicting redirects with setActive
          await new Promise((resolve) => setTimeout(resolve, 250))
          const redirectUrl = modalRedirect ?? props.redirectTo ?? "/"
          if (redirectUrl.startsWith("https:"))
            window.location.href = redirectUrl
          else router.push(redirectUrl)
        }
      }
    } catch (error) {
      form.setError("root", { message: "Invalid email or password" })
      assignClerkAPIErrorsToForm(form, error, {
        username: "username",
        password: "password",
      })
    }
  }

  return (
    <Form.Root
      onSubmit={form.handleSubmit(processForm)}
      className="w-full sm:min-w-[280px]"
      noValidate
    >
      <FormFieldInput
        registry={form.register("email")}
        error={form.formState.errors.email?.message}
        label="Email"
        inputHtmlAttributes={{
          autoCapitalize: "off",
          autoComplete: "email",
          type: "email",
          autoFocus: true,
        }}
      />
      <FormFieldInput
        registry={form.register("password")}
        error={form.formState.errors.password?.message}
        label="Password"
        inputHtmlAttributes={{
          autoComplete: "current-password",
          type: "password",
        }}
      />
      {!!form.formState.errors.root?.message && (
        <p className={formErrorClassName}>
          {form.formState.errors.root?.message}
        </p>
      )}
      <Form.Submit asChild>
        <Button
          variant="default"
          className={cn("w-full", props.classNames?.submitButton)}
          disabled={form.formState.isSubmitting}
        >
          Log in
        </Button>
      </Form.Submit>
    </Form.Root>
  )
}
