"use client"

import { useAuth } from "@clerk/nextjs"
import { useEffect } from "react"
import { useRouter } from "next/navigation"
import { dashboardUrl } from "../../../../constants"

export const SignInObserver = () => {
  const { isSignedIn } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (isSignedIn) {
      router.push(`${dashboardUrl}`)
    }
  }, [isSignedIn, router])

  return null
}
