"use client"

import { parseAsString, useQueryStates } from "nuqs"
import { useAuth } from "@clerk/nextjs"
import { create } from "zustand"

export type AuthModalMode = "sign-in" | "sign-up" | "forgot-password"

export type AuthModalState = {
  footer: boolean
  toggleFooter: (toggle: boolean) => void
}

const useLaunchModalState = create<AuthModalState>((set) => ({
  footer: true,
  toggleFooter: (toggle: boolean) => set({ footer: toggle }),
}))

export type AuthModalOpenParams = {
  auth?: AuthModalMode
  redirectUrl?: string
}

export const useAuthModal = () => {
  const { isSignedIn } = useAuth()
  const [query, setQuery] = useQueryStates({
    auth: parseAsString,
    redirect_url: parseAsString,
    __clerk_redirect_url: parseAsString,
  })
  const { footer: showFooter, toggleFooter } = useLaunchModalState()

  return {
    loggedIn: isSignedIn,
    redirectTo: query.redirect_url || query.__clerk_redirect_url || undefined,
    mode: query.auth as AuthModalMode | null,
    footer: showFooter,
    open: (params?: AuthModalOpenParams) => {
      toggleFooter(true)
      setQuery(
        {
          auth: params?.auth ?? "sign-in",
          redirect_url: params?.redirectUrl ?? null,
        },
        { history: "push" },
      ).then()
    },
    onOpenChange: (open: boolean) => {
      if (open) return
      setQuery({
        auth: null,
        redirect_url: null,
      }).then()
    },
    isOpen: query.auth !== null,
    setMode: (variant: AuthModalMode) => {
      setQuery({
        auth: variant ?? null,
      }).then()
    },
    toggleFooter: (toggle: boolean) => {
      toggleFooter(toggle)
    },
  }
}
